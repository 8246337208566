<template>
    <div class="vip-content">
        <div class="vip-title">
            <img src="@/assets/img/i-tit2.png" alt="">
            大包装订购与询价
        </div>
        <div class="vip-content-inner">
            <div class="rich-text">
                <p> 尊敬的客户：</p>
                <p>
                    如果您需要其他规格与包装的产品，请填写以下信息，并留下您的联系方式，我们的客服人员会及时与您联系，谢谢您对我们工作的支持!</p>

                <el-form ref="packageForm" :model="packageForm" :rules="packageRules">
                    <div class="row">
                        <el-form-item label="联系人姓名" prop="username">
                            <el-input v-model="packageForm.username"></el-input>
                        </el-form-item>
                        <el-form-item label="手机号" prop="phone">
                            <el-input v-model="packageForm.phone"></el-input>
                        </el-form-item>
                        <el-form-item label="邮箱" prop="e-mail">
                            <el-input v-model="packageForm.email"></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item label="公司名称" prop="companyName">
                        <el-input v-model="packageForm.companyName"></el-input>
                    </el-form-item>
                    <el-form-item label="大包装询价" prop="remarks">
                        <div class="tips">该部分信息请包括产品编号、产品名称、规格(请注明：浓废/纯魔/级别等)、数量、预算价格等信息。</div>
                        <el-input type="textarea"  show-word-limit maxlength="500" v-model="packageForm.remarks"></el-input>
                    </el-form-item>
                </el-form>

                <div class="g-btn small send" @click="submitPackage()">
                    <img src="@/assets/img/i-send.png" alt="">
                    提交信息
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import {consultpackageSave} from "@/api/Product";

export default {
    name: "largePackaging",
    data() {
        return {
			packageForm: {
				username: '',
				phone: '',
				companyName	: '',
				email:'',
				remarks:'',
			},
			packageRules: {
				username: [{required: true, trigger: 'blur', message: '必填项'}],
				phone: [{required: true, trigger: 'blur', message: '必填项'}],
				companyName: [{required: true, trigger: 'blur', message: '必填项'}],
			},
        }
    },
	mounted(){

	},
	methods:{
		cancelForm() {//
			this.loadings = false;
			this.packageForm= { // 清空数值
				username: '',
				phone: '',
				companyName	: '',
				email:'',
				remarks:'',
			}
			this.$refs.packageForm.clearValidate(); //弹窗关闭清除校验
		},

    	// ——————————————————————————————接口请求
		// 提交大包装订购与询价
		submitPackage(){
			this.$refs.packageForm.validate(valid => {
				if (valid) {
					if(!(/^1[3456789]\d{9}$/.test(this.packageForm.phone))){
						this.$message({
							type: 'warning',
							message: '手机号格式不正确！',
							duration: 1500
						});
						return
					}
					let reg = /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/
					if(this.packageForm.email!=''&&!(reg.test(this.packageForm.email))){
						this.$message({
							type: 'warning',
							message: '邮箱格式不正确！',
							duration: 1500
						});
						return
					}
					console.log('提交询价')
					consultpackageSave(this.packageForm).then((res) => {
						if(res.data.code==200){
							this.$message({
								type: 'success',
								message: '提交成功',
								duration: 1500
							});
							this.cancelForm()

						}else{
							this.$message({
								type: 'error',
								message: res.data.message,
								duration: 1500
							});
						}
					})
				}
			})
		},
	},
}
</script>

<style scoped>

</style>
