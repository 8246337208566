
import qs from 'qs'
import request from '@/utils/request'

// 加入购物车
export function joincartSave(params) {
    return request({
        url: '/blade-trade/customer-goods-cart/save',
        method: 'post',
        data: params,
        // data: qs.stringify(params),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8', // application/x-www-form-urlencoded
            // 'Authorization':"Basic c3dvcmQ6c3dvcmRfc2VjcmV0",
            // 'Tenant-Id':"000000",
        }
    })
}
// 购物车列表
export function customerGoodsCartList(params) {
    return request({
        url: '/blade-trade/customer-goods-cart/list',
        method: 'get',
        params: params,
    })
}
// 购物车分页
export function customerGoodsCartPage(params) {
    return request({
        url: '/blade-trade/customer-goods-cart/page',
        method: 'get',
        params: params,
    })
}
// 购物车删除商品
export function customerGoodsCartRemove(params) {
    return request({
        url: '/blade-trade/customer-goods-cart/remove',
        method: 'post',
        params: params,
    })
}
// 购物车更新商品数量
export function customerGoodsCartUpdate(params) {
    return request({
        url: '/blade-trade/customer-goods-cart/update',
        method: 'post',
        data: params,
    })
}

// 批量查询商品信息列表
export function customerGoodsList(params) {
    return request({
        url: '/blade-goods/goods-info/list-by-customer',
        method: 'get',
        params: params,
    })
}
// 系统配置接口
export function systemConfig(params) {
    return request({
        url: '/blade-trade/system-config/detail',
        method: 'get',
        params: params,
    })
}

// 提交订单
export function orderInfoSave(params) {
    return request({
        url: '/blade-trade/order-info/save',
        method: 'post',
        data: params,
        // data: qs.stringify(params),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8', // application/x-www-form-urlencoded
        }
    })
}
// 支付
export function orderInfoPay(params) {
    return request({
        url: '/blade-trade/order-info/pay',
        method: 'post',
        params: params,
        // data: qs.stringify(params),
        // headers: {
        //     'Content-Type': 'application/json;charset=UTF-8', // application/x-www-form-urlencoded
        // }
    })
}
// 用订单号查询订单详情 轮询支付状态
export function orderInfoNumber(params) {
    return request({
        url: '/blade-trade/order-info/detail-by-number',
        method: 'get',
        params: params,
    })
}



// 客户订单分页
export function pageByCustomer(params) {
    return request({
        url: '/blade-trade/order-info/page-by-customer',
        method: 'get',
        params: params,
    })
}
// 订单详情
export function orderInfoDetail(params) {
    return request({
        url: '/blade-trade/order-info/detail',
        method: 'get',
        params: params,
    })
}
// 订单商品列表
export function ordergoodslist(params) {
    return request({
        url: '/blade-trade/order-info/goods-list',
        method: 'get',
        params: params,
    })
}

// 发货记录
export function orderdeliverylist(params) {
    return request({
        url: '/blade-trade/order-delivery/list',
        method: 'get',
        params: params,
    })
}
// 快递记录详情
export function orderdeliveryExpressRecord(params) {
    return request({
        url: '/blade-trade/order-delivery/express-record',
        method: 'get',
        params: params,
    })
}
// 发货记录详情
export function orderdeliverydetail(params) {
    return request({
        url: '/blade-trade/order-delivery/detail',
        method: 'get',
        params: params,
    })
}

// 积分订单发货记录
export function integralorderdeliverylist(params) {
    return request({
        url: '/blade-trade/order-integral-delivery/list',
        method: 'get',
        params: params,
    })
}
// 积分快递记录详情
export function integralorderdeliveryExpressRecord(params) {
    return request({
        url: '/blade-trade/order-integral-delivery/express-record',
        method: 'get',
        params: params,
    })
}
// 积分发货记录详情
export function integralorderdeliverydetail(params) {
    return request({
        url: '/blade-trade/order-integral-delivery/detail',
        method: 'get',
        params: params,
    })
}

// 余额记录
export function moneyRecordpPage(params) {
    return request({
        url: '/blade-user/customer-money-record/page',
        method: 'get',
        params: params,
    })
}

// 积分记录
export function integralRecordpPage(params) {
    return request({
        url: '/blade-user/customer-integral-record/page',
        method: 'get',
        params: params,
    })
}














