
import qs from 'qs'
import request from '@/utils/request'
import {joincartSave} from "@/api/transaction";

// 产品信息
export function goodsInfoDetail(params) {
    return request({
        url: '/blade-goods/goods-info/open/detail',
        method: 'get',
        params: params,
        // data: qs.stringify(params),
        // headers: {
        //     'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        //     'Authorization':"Basic c3dvcmQ6c3dvcmRfc2VjcmV0",
        //     'Tenant-Id':"000000",
        // }
    })
}

//coa列表
export function goodscoaList(params) {
    return request({
        url: '/blade-goods/goods-coa/open/list',
        method: 'get',
        params: params,
    })
}
//cas详情
export function goodscasDetail(params) {
    return request({
        url: '/blade-goods/goods-cas/open/detail',
        method: 'get',
        params: params,
    })
}

// MSDS列表
export function goodsmsdsList(params) {
    return request({
        url: '/blade-goods/goods-msds/open/list',
        method: 'get',
        params: params,
    })
}

// 规格列表
export function specificationList(params) {
    return request({
        url: '/blade-goods/goods-specification/open/list',
        method: 'get',
        params: params,
    })
}

// 提交商品询价
export function consultgoodsSave(params) {
    return request({
        url: '/blade-user/consult-goods/open/save',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    })
}

// 提交大包装订购
export function consultpackageSave(params) {
    return request({
        url: '/blade-user/consult-package/open/save',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    })
}

// 下载模板
export function exportCartTemplate(params) {
    return request({
        url: '/blade-goods/goods-info/export-cart-template',
        method: 'get',
        data: params,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    })
}

// 编号批量查询商品
export function numGoodsInfoList(params) {
    return request({
        url: '/blade-goods/goods-info/open/list',
        method: 'GET',
        params: params,
    })
}

// 积分区间列表
export function integralRangeList(params) {
    return request({
        url: '/blade-goods/integral-range/open/list',
        method: 'get',
        params: params,
    })
}

// 积分商品分类列表
export function integralGoodstypeList(params) {
    return request({
        url: '/blade-goods/integral-goods-type/open/list',
        method: 'get',
        params: params,
    })
}
// 积分商品批量查询
export function customerJFGoodsList(params) {
    return request({
        url: '/blade-goods/integral-goods-info/list-by-customer',
        method: 'get',
        params: params,
    })
}
// 积分商品分页
export function integralGoodsPage(params) {
    return request({
        url: '/blade-goods/integral-goods-info/open/page',
        method: 'get',
        params: params,
    })
}
// 积分商品详情
export function integralGoodsDetail(params) {
    return request({
        url: '/blade-goods/integral-goods-info/open/detail',
        method: 'get',
        params: params,
    })
}
// 积分商品加购物车
export function customerIntegralCartsave(params) {
    return request({
        url: '/blade-trade/customer-integral-cart/save',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8', // application/x-www-form-urlencoded
        }
    })
}
// 积分框列表
export function customerIntegralCartpage(params) {
    return request({
        url: '/blade-trade/customer-integral-cart/page',
        method: 'get',
        params: params,
    })
}
// 积分框商品删除
export function integralCartRemove(params) {
    return request({
        url: '/blade-trade/customer-integral-cart/remove',
        method: 'post',
        params: params,
    })
}

// 积分商品提交订单
export function orderIntegralSave(params) {
    return request({
        url: '/blade-trade/order-integral/save',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8', // application/x-www-form-urlencoded
        }
    })
}
// 积分订单列表
export function orderIntegralPage(params) {
    return request({
        url: '/blade-trade/order-integral/page',
        method: 'get',
        params: params,
    })
}
// 积分订单商品
export function orderIntegralgoodslist(params) {
    return request({
        url: '/blade-trade/order-integral/integral-goods-list',
        method: 'get',
        params: params,
    })
}
// 积分订单详情
export function orderIntegralDetail(params) {
    return request({
        url: '/blade-trade/order-integral/detail',
        method: 'get',
        params: params,
    })
}





